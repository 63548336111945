import React from "react"

import Layout from "~/components/layout"
import SEO from "~/components/seo"
import PageHeading from "~/components/styled/page-heading"
import FloatingText from "~/components/styled/floatingtext"

const NotFoundPage = () => (
  <Layout>
    <SEO seo={{ title: "404: Not found" }} />
    <PageHeading>404: Not Found</PageHeading>
    <FloatingText>You just hit a route that doesn&#39;t exist... the sadness.</FloatingText>
  </Layout>
)

export default NotFoundPage

import React from "react"
import { StaticQuery, graphql } from "gatsby"

import Layout from "~/components/layout"
import SEO from "~/components/seo"



const IndexPage = ({ data }) => {
  const seo = { title: "0x01 records" }

  return (
    <Layout>
      <SEO seo={seo} />
        <div className="w-full max-w-full md:max-w-2/3 md:w-2/3 px-6 md:px-2">
      </div>


    </Layout>
  )
}


export default IndexPage

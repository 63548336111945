import React from "react"
import { graphql } from "gatsby"

import Layout from "~/components/layout"
import PageHeading from "~/components/styled/page-heading"
import CatProdList from "~/components/catprod-list"
import SEO from "~/components/seo"

const CategoryPage = ({ data }) => {
  const products = data.strapiCategory.products
    const catdet = data.strapiCategory
      const releases = data.strapiCategory.releases
  const seo = {
    title: data.strapiCategory.name,
  }

  return (
    <Layout>
      <SEO seo={seo} />
      <div>
        <PageHeading>{data.strapiCategory.name}</PageHeading>
        <CatProdList products={products} catdet={catdet} releases={releases} />
      </div>
    </Layout>
  )
}

export const query = graphql`
  query CategoryQuery($slug: String!) {
    strapiCategory(
      slug: { eq: $slug }
    ) {
      name
      bio
      booking
      releases {
        catalog
        date
        name
        id
      }
      image {
        localFile {
          childImageSharp {
            gatsbyImageData(
              layout: FULL_WIDTH
              placeholder: BLURRED
              aspectRatio: 1
            )
          }
        }
      }
      products {
        title
        slug
        id
        release
        catalognumber
        image {
          localFile {
            childImageSharp {
              gatsbyImageData(
                layout: FULL_WIDTH
                placeholder: BLURRED
                aspectRatio: 1
              )
            }
          }
        }
      }
    }
  }
`

export default CategoryPage

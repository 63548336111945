import React from "react"
import { Link } from "gatsby"
import PropTypes from "prop-types"
import rehypeRaw from 'rehype-raw';
import rehypeExternalLinks from 'rehype-external-links'
import Card from "~/components/styled/card"
import Image from "~/components/image"
import ReactMarkdown from "react-markdown"

const CatProdList = ({ products, catdet, releases, gridCols }) => {

  const customRenderers = {
    a: ({ href, children }) => (
      <a
        href={href}
        target="_blank"
        rel="noopener noreferrer"
        className="inline-block p-2 text-center text-xl font-medium rounded-md border-2 mt-4 mr-4 prodb"
      >
        {children}
      </a>
    ),
  };

  return (
    <div className="flex flex-col md:grid md:grid-cols-2">
      <div className="order-2 md:order-1">
        <h2 className="px-5 md:pt-10 text-3xl">Releases on 0x01</h2>
        <div className={`grid px-0 py-5 grid-cols-1 md:grid-cols-2 gap-0`}>
          {products.map(product => {
            return (
              <Card key={product.id}>
                <Link to={`/releases/${product.slug}`} key={product.id}>
                  <Image
                    alt="Product Image"
                    className=""
                    image={product.image}
                  />
                  <div className="flex flex-col px-4 text-2xl py-6 cardbg">
                    <p className="text-xl">
                      {product.catalognumber}
                    </p>
                    <p className="text-2xl">{product.title}</p>
                  </div>
                </Link>
              </Card>
            )
          }).reverse()}
        </div>
        {catdet.booking && (
          <div className="px-5 md:pt-10 pt-10">
            <h2 className="text-3xl">Booking</h2>
            <p className="text-xl">contact us <Link to="/contact/" className="underline">here</Link></p>
          </div>
        )}
      </div>
      <div className="px-0 order-1 md:order-2">
        {catdet.image && (
          <div className="">
            <Image
              className="imageartist"
              image={catdet.image}
              alt="Product Image"
            />
          </div>
        )}
        {catdet.bio && (
          <ReactMarkdown
            className="mt-10 px-5"
            children={catdet.bio}
            rehypePlugins={[
              rehypeRaw,
              [rehypeExternalLinks, { target: '_blank', rel: 'noopener noreferrer' }]
            ]}
            components={customRenderers}
          />
        )}
      </div>
    </div>
  )
}

CatProdList.propTypes = {
  products: PropTypes.array,
  gridCols: PropTypes.string,
}

CatProdList.defaultProps = {
  gridCols: "grid-cols-1 md:grid-cols-3",
}

export default CatProdList

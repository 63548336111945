import React from "react"
import { StaticQuery, graphql } from "gatsby"

import github from "~/images/github.svg"
import bandcamp from "~/images/bandcamp.svg"
import twitter from "~/images/twitter.svg"
import facebook from "~/images/facebook.svg"
import soundcloud from "~/images/soundcloud.svg"
import instagram from "~/images/instagram.svg"

const Footer = ({ linkurls }) => {

  return (
    <footer className="flex justify-center mt-10 md:justify-end ph-4 w-full items-center pr-10">

      <div className="flex gap-2 ml-4 items-center socialicons">
        <a
          href={linkurls.Instagram}
          className="w-8 flex p-3"
          target="_blank"
        >
          <img src={instagram} alt="Instagram" />
        </a>
        <a
          href={linkurls.Bandcamp}
          className="w-6 flex p-4"
          target="_blank"
        >
          <img src={bandcamp} alt="Bandcamp" />
        </a>
        <a href={linkurls.Twitter}
          className="w-6 flex p-4"
          target="_blank"
        >
          <img src={twitter} alt="Twitter" />
        </a>
        <a
          href={linkurls.Soundcloud}
          className="w-10 flex p-1"
          target="_blank"
        >
          <img src={soundcloud} alt="Soundcloud" />
        </a>

        <a href={linkurls.Facebook} className="w-5 flex p-4"
        target="_blank">
          <img src={facebook} alt="Facebook" /><p></p>
        </a>
      </div>
    </footer>
  )
}



export default Footer

import React from "react"
import ReactMarkdown from "react-markdown"
import BandcampPlayer from "react-bandcamp"
import ReactSoundCloud from '../../components/ReactSoundCloud';
import { Link } from "gatsby"

import { graphql } from "gatsby"

import Layout from "~/components/layout"
import ProductList from "~/components/product-list"
import SEO from "~/components/seo"
import Image from "~/components/image"

import { formatPrice } from "~/helpers/currency-formatter"


const ProductPage = ({ data }) => {
  const product = data.strapiProduct

  const seo = {
    title: product.title,
    shareImage: product.image,
  }
let comp
if(product.embed) {
  let text = product.embed
  if(text.includes('soundcloud')) {
    comp = <div className="my-6 mb-2"><ReactSoundCloud url={product.embed} height="320px" color="ff00c1" visual="false" hideRelated="true" width="99%" /></div>
  } else {
    comp = <div className="my-6 mb-2"><BandcampPlayer album={product.embed} size="large" bgcol="black" artwork="small" height="220px" width="100%" /></div>
  }
}

let blinks
if(product.hidelinks) {
  blinks = <div>links, preorder & more info coming soon</div>
} else {
  blinks = product.buylinks.bandcamp && (
    <div>
      {(product.preorder || product.preorder == true ) && (<h3 className="mt-4 text-3xl">Preorder</h3>)}
      {(!product.preorder  || product.preorder == false) && (<h3 className="mt-4 text-3xl">Buy</h3>)}
      <div className="flex flex-row gap-4">
      {product.buylinks.bandcamp && (
      <a
        href={product.buylinks.bandcamp}
        target="_blank"
        rel="noreferrer"
        className="p-2 text-center text-xl font-medium rounded-md border-2 mt-4 prodb"
      >
        Bandcamp
      </a>)}
      {product.buylinks.junodownload && (
      <a
        href={product.buylinks.junodownload}
        target="_blank"
        rel="noreferrer"
        className="p-2 text-center text-xl font-medium rounded-md border-2 mt-4 prodb"
      >
        Junodownload
      </a>)}
      {product.buylinks.beatport && (
      <a
        href={product.buylinks.beatport}
        target="_blank"
        rel="noreferrer"
        className="p-2 text-center text-xl font-medium rounded-md border-2 mt-4 prodb"
      >
        Beatport
      </a>)}
      </div>
      {((!product.preorder  || product.preorder == false) || product.buylinks.youtube) && (<h3 className="mt-4 text-3xl">Stream</h3>)}
      <div className="flex flex-row gap-4">
      {product.buylinks.youtube && (
      <a
        href={product.buylinks.youtube}
        target="_blank"
        rel="noreferrer"
        className="p-2 text-center text-xl font-medium rounded-md border-2 mt-4 prodb"
      >
        Youtube
      </a>)}
      {product.buylinks.soundcloud && (
      <a
        href={product.buylinks.soundcloud}
        target="_blank"
        rel="noreferrer"
        className="p-2 text-center text-xl font-medium rounded-md border-2 mt-4 prodb"
      >
        Soundcloud
      </a>)}
      {product.buylinks.applemusic && (
      <a
        href={product.buylinks.applemusic}
        target="_blank"
        rel="noreferrer"
        className="p-2 text-center text-xl font-medium rounded-md border-2 mt-4 prodb"
      >
        Apple music
      </a>)}
      {product.buylinks.applemusic && (
      <a
        href={product.buylinks.spotify}
        target="_blank"
        rel="noreferrer"
        className="p-2 text-center text-xl font-medium rounded-md border-2 mt-4 prodb"
      >
        Spotify
      </a>)}
      </div>
    </div>
 )
}

  return (
    <Layout>
      <SEO seo={seo} />

      <div className="grid p-0 md:pr-10 grid-cols-1 md:grid-cols-2"> {/* vorher: px-5 md:px-20 */}
        <div className="w-full gap-0">
          {product.image && (
            <div className="md:col-span-2">
              <Image
                className=""
                image={product.image}
                alt="Product Image"
              />
            </div>
          )}



        </div>
        <div className="pl-5 md:pl-20 pr-5 md:pr-0 pt-10 md:pt-0">
          <h1 className="text-3xl sm:text-3xl md:text-4xl xl:text-5xl mb-6 prodtitle" data-text= {product.title}>{product.title}</h1>

            {product.category.map(prodcat => (
                <h2 className="text-3xl mb-1" ><Link to={`/categories/${prodcat.slug}`} key={prodcat.id} className="underline">{prodcat.name}</Link></h2>
            ))}
          <h3 className="text-2xl mb-1">{product.catalognumber && (product.catalognumber)}</h3>
          <h4 className="text-2xl mb-1">{product.release && (
              <div>
                Release: {product.release}
              </div>
            )}</h4>


              {comp}

              {blinks}
          {product.description && (
          <div className="my-6 mb-24">
            <ReactMarkdown
              className="prose"
              children={product.description}
            />
          </div>)}

        </div>

{/*
        {product.relatedProducts.length > 0 && (
          <div className="flex flex-col my-6 mb-24">
            <h2 className="text-3xl font-bold text-center">Related Products</h2>
            <hr className="mt-6 mb-12 m-auto w-24 border-t-4" />
            <ProductList
              products={product.relatedProducts}
              gridCols="grid-cols-1 md:grid-cols-2"
            />
          </div>
        )}
        */}
      </div>
    </Layout>
  )
}

export const query = graphql`
  query ProductQuery($slug: String!) {
    strapiProduct(slug: { eq: $slug }) {
      category {
        name
        id
        slug
      }
      title
      catalognumber
      description
      id
      release(formatString: "DD MMMM, YYYY")
      embed
      preorder
      hidelinks
      buylinks {
        bandcamp
        soundcloud
        spotify
        junodownload
        beatport
        youtube
        applemusic
      }
      image {
        localFile {
          publicURL
          childImageSharp {
            gatsbyImageData(
              layout: FULL_WIDTH
              placeholder: BLURRED
              aspectRatio: 1
            )
          }
        }
      }
      relatedProducts {
        title
        id
        slug
        image {
          localFile {
            childImageSharp {
              gatsbyImageData(
                layout: FULL_WIDTH
                placeholder: BLURRED
                aspectRatio: 1
              )
            }
          }
        }
      }
    }
  }
`

export default ProductPage

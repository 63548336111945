import React from "react";
import ParticleField from "react-particles-webgl";
//import useMousePosition from "~/components/useMousePosition"

function BackGround() {

  //const { clientX, clientY } = useMousePosition();

  const config = {
    showCube: false,
    dimension: '3D',
    velocity: 0.1,
    boundaryType: 'passthru',
    antialias: false,
    direction: {
      xMin: -0.3,
      xMax: 0.2,
      yMin: -1,
      yMax: -0.2,
      zMin: -0.1,
      zMax: 0.1
    },
    lines: {
      colorMode: 'solid',
      color: '#545454',
      transparency: 0.9,
      limitConnections: true,
      maxConnections: 20,
      minDistance: 250,
      visible: true
    },
    particles: {
      colorMode: 'solid',
      color: '#545454',
      transparency: 0.7,
      shape: 'circle',
      boundingBox: 'canvas',
      count: 800,
      minSize: 0,
      maxSize: 6,
      visible: true
    },
    cameraControls: {
      enabled: false,
      enableDamping: true,
      dampingFactor: 0.2,
      enableZoom: true,
      autoRotate: true,
      autoRotateSpeed: 0.1,
      resetCameraFlag: true
    },
    maxConnections: 30,
    limitConnections: true
  }


  return (
    <div className="background">
      <ParticleField config={config} />
    </div>
  );
}

export default BackGround;

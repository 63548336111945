import React, { useEffect } from "react"
import { Link } from "gatsby"
import PropTypes from "prop-types"

import StrapiLogo from "~/images/Logo_0X01.png"
import github from "~/images/github.svg"
import twitter from "~/images/twitter.svg"
import facebook from "~/images/facebook.svg"



const Header = ({ setOpenModal }) => {

  useEffect(() => {
    // Create the script element
    const script = document.createElement('script');
    script.type = 'text/javascript';
    script.async = true;
    script.innerHTML = `
      var _paq = window._paq = window._paq || [];
      /* tracker methods like "setCustomDimension" should be called before "trackPageView" */
      _paq.push(['trackPageView']);
      _paq.push(['enableLinkTracking']);
      (function() {
        var u="//tracking.x02.ch/";
        _paq.push(['setTrackerUrl', u+'matomo.php']);
        _paq.push(['setSiteId', '4']);
        var d=document, g=d.createElement('script'), s=d.getElementsByTagName('script')[0];
        g.async=true; g.src=u+'matomo.js'; s.parentNode.insertBefore(g,s);
      })();
    `;
    // Append the script to the document head
    document.head.appendChild(script);

    // Cleanup script when component unmounts
    return () => {
      document.head.removeChild(script);
    };
  }, []);
  return (
    <header className="p-8 pb-10 md:pb-0">


        <div className="flex flex-col md:justify-between items-left md:flex-row">
          <div className="place-self-center md:place-self-auto">
            <Link className="mx-2 mb-2 md:mb-0 text-xl text-white" to="/">
              Home
            </Link>

            <Link className="mx-2 mb-2 md:mb-0 text-xl text-white" to="/releases">
              Releases
            </Link>
            <Link className="mx-2 mb-2 md:mb-0 text-xl text-white" to="/categories">
              Artists
            </Link>
            <Link className="mx-2 mb-2 md:mb-0 text-xl text-white" to="/contact">
              Contact
            </Link>{/*
            <button
              className="mx-2 mb-2 md:mb-0 text-xl text-white"
              onClick={() => setOpenModal(true)}
            >
              Search
            </button>
            */}
            {/* how do i add my matomo trackin code here? */}
          </div>

          <Link className="mx-2 text-lg w-20 mb-4 mt-7 md:mt-0 place-self-center md:place-self-auto" to="/">
            <img src={StrapiLogo} alt="strapi catalog logo" />
          </Link>
        </div>

    </header>
  )
}

Header.propTypes = {
  siteName: PropTypes.string,
}

Header.defaultProps = {
  siteName: ``,
}

export default Header

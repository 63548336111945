import React from "react"
import { StaticQuery, graphql } from "gatsby"

import Layout from "~/components/layout"
import SEO from "~/components/seo"
import CategoryList from "~/components/category-list"
import PageHeading from "~/components/styled/page-heading"
import FloatingText from "~/components/styled/floatingtext"



const IndexPage = ({ data }) => {
  const seo = { title: "0x01 records" }
  const contact = data.strapiGlobal.ContactDescription
  const contactadd = data.strapiGlobal.ContactAddition

  return (
    <Layout>
      <SEO seo={seo} />
      <PageHeading>Contact</PageHeading>
      <FloatingText>{contact}</FloatingText>
        <div className="w-full max-w-full md:max-w-2/3 md:w-2/3 px-6 md:px-2">
         <form method="post" netlify-honeypot="bot-field" data-netlify="true" name="contact" className="shadow-md w-half px-8 pt-6 pb-8 mb-4" id="contactform">
           <input type="hidden" name="bot-field" />
           <input type="hidden" name="form-name" value="contact" />
          <label className="block text-gray-50 text-lg mb-2">
            Email
            <input type="email" name="email" className="shadow appearance-none rounded w-full py-2 px-3 text-black font-bold leading-tight focus:outline-none focus:shadow-outline" />
          </label>
          <label className="block text-gray-50 text-lg mb-2">
            Name
            <input type="text" name="name" className="shadow appearance-none rounded w-full py-2 px-3 text-black font-bold leading-tight focus:outline-none focus:shadow-outline" />
          </label>
          <label className="block text-gray-50 text-lg mb-2">
            Message
            <textarea name="message" className="shadow appearance-none rounded w-full py-2 px-3 text-black font-bold leading-tight focus:outline-none focus:shadow-outline" />
          </label>

          <button type="submit" className="bg-green-900 mt-2 hover:bg-gray-700 text-white py-2 px-4 rounded focus:outline-none focus:shadow-outline">Send</button>
        </form>
        <div className="px-6"><p>{contactadd}</p></div>
      </div>


    </Layout>
  )
}

export const query = graphql`
  query {
    strapiGlobal {
      ContactDescription
      ContactAddition
      }
  }
`

export default IndexPage

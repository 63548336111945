import addToMailchimp from 'gatsby-plugin-mailchimp'

import React from "react"
export default class EmailListForm extends React.Component {

  state = {
    email: "",
    message:""
  }

  _handleSubmit = e => {
    e.preventDefault()

    const result = addToMailchimp(this.state.email).then(data => {
      console.log(data)
      this.setState({ message: data.msg });
    })
    //this.setState({result: result})
  }
  handleInputChange = event => {
  const target = event.target
  const value = target.value
  const name = target.name
  this.setState({
    [name]: value,
  })
}


render () {
    return (
      <form onSubmit={this._handleSubmit} className="mailform shadow-md w-half px-20 pt-6 pb-8 mb-4 text-right">
        <div className="form-row">
          <label className="block text-gray-50 text-lg mb-2">
              <p className="text-2xl">Your email address</p>
              <input className="email" value={this.state.email} onChange={this.handleInputChange} type="email" name="email" className="shadow appearance-none rounded w-full md:w-2/3 py-2 px-3 text-black font-bold leading-tight focus:outline-none focus:shadow-outline" />
          </label>
        </div>
        <div className="message text-2xl mailcmess" dangerouslySetInnerHTML={{ __html: this.state.message}} />
        <button className="button" type="submit" className="text-2xl colorpink bg-green-900 mt-2 hover:bg-gray-700 text-white py-2 px-4 rounded focus:outline-none focus:shadow-outline">
          Subscribe
        </button>
      </form>
    )
  }
}

import React from "react"
import { Link } from "gatsby"
import PropTypes from "prop-types"

import Card from "~/components/styled/card"
import Image from "~/components/image"


const ProductList = ({ products, gridCols }) => {

  return (
    <div className="grid py-5 grid-cols-1 md:grid-cols-3 2xl:grid-cols-5 gap-0"> {/* vorher: gap-6 px-5 md:px-20  */}
      {products.map(product => {



        return (
          <Card key={product.id}>
            <Link to={`/releases/${product.slug}`} key={product.id}>
              <Image
                alt="Product Image"
                className=""
                image={product.image}
              />
            <div className="flex flex-col px-4 text-2xl py-6 cardbg">
              <p className="text-xl">
                {product.catalognumber}
              </p>
                {product.category.map(prodcat => (

                  <p className="text-4xl">{ prodcat.name }</p>
                ))}

                <p className="text-2xl">{product.title}</p>

              </div>
            </Link>
          </Card>
        )
      })}
    </div>
  )
}

ProductList.propTypes = {
  products: PropTypes.array,
  gridCols: PropTypes.string,
}

ProductList.defaultProps = {
  gridCols: "grid-cols-1 md:grid-cols-3",
}

export default ProductList

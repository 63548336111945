// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-categories-index-js": () => import("./../../../src/pages/categories/index.js" /* webpackChunkName: "component---src-pages-categories-index-js" */),
  "component---src-pages-categories-strapi-category-slug-js": () => import("./../../../src/pages/categories/{StrapiCategory.slug}.js" /* webpackChunkName: "component---src-pages-categories-strapi-category-slug-js" */),
  "component---src-pages-contact-index-js": () => import("./../../../src/pages/contact/index.js" /* webpackChunkName: "component---src-pages-contact-index-js" */),
  "component---src-pages-empty-index-js": () => import("./../../../src/pages/empty/index.js" /* webpackChunkName: "component---src-pages-empty-index-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-releases-index-js": () => import("./../../../src/pages/releases/index.js" /* webpackChunkName: "component---src-pages-releases-index-js" */),
  "component---src-pages-releases-strapi-product-slug-js": () => import("./../../../src/pages/releases/{StrapiProduct.slug}.js" /* webpackChunkName: "component---src-pages-releases-strapi-product-slug-js" */),
  "component---src-pages-using-typescript-tsx": () => import("./../../../src/pages/using-typescript.tsx" /* webpackChunkName: "component---src-pages-using-typescript-tsx" */)
}


// src/components/ReactSoundCloud.js

import React from 'react';
import PropTypes from 'prop-types';

const ReactSoundCloud = ({ url, height, color, visual, hideRelated, width }) => {
  const iframeSrc = `https://w.soundcloud.com/player/?url=${encodeURIComponent(url)}&color=%23${color}&auto_play=false&hide_related=${hideRelated}&show_comments=true&show_user=true&show_reposts=false&visual=${visual}`;

  return (
    <iframe
      width={width}
      height={height}
      scrolling="no"
      frameBorder="no"
      allow="autoplay"
      src={iframeSrc}
      title="SoundCloud Player"
    ></iframe>
  );
};

ReactSoundCloud.propTypes = {
  url: PropTypes.string.isRequired,
  height: PropTypes.string,
  color: PropTypes.string,
  visual: PropTypes.string,
  hideRelated: PropTypes.string,
  width: PropTypes.string,
};

ReactSoundCloud.defaultProps = {
  height: '320px',
  color: 'ff00c1',
  visual: 'false',
  hideRelated: 'true',
  width: '100%',
};

export default ReactSoundCloud;

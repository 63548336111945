import React, { useState } from "react"
import PropTypes from "prop-types"
import { useStaticQuery, graphql } from "gatsby"




import Footer from "~/components/footer"

import Header from "~/components/header"
import BackGround from '~/components/background';



const Layout = ({ children }) => {
  const data = useStaticQuery(graphql`
    query SiteNameQuery {
      strapiGlobal {
        siteName
        Instagram
        Soundcloud
        Twitter
        Bandcamp
        Facebook
      }
    }
  `)

  const [openModal, setOpenModal] = useState(false)

  return (
    <div>
          <BackGround />
    <div className="bg-transparent relative min-h-screen">

      <Header
        setOpenModal={setOpenModal}
        siteName={data.strapiGlobal.siteName || `Strapi`}
      />
      <div className="flex flex-col p-0">
        <main className="flex-1">{children}</main>
        <Footer linkurls={data.strapiGlobal} />
      </div>


    </div>
    </div>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout

import React from "react"


const PageHeading = ({ children }) => (
  <div className="w-full text-center md:text-left mb-8 md:mb-3">
  <h1 className="text-white inline-block text-5xl sm:text-6xl md:text-6xl lg:text-8xl text-center md:text-left w-2/3 pagehead" data-text={children}>
    {children}
  </h1>
  </div>
)

export default PageHeading

import React from "react"
import { Link } from "gatsby"
import PropTypes from "prop-types"

import Image from "~/components/image"
import Card from "~/components/styled/card"

const CategoryList = ({ categories }) => (
  <div className="grid py-5 grid-cols-1 md:grid-cols-3 2xl:grid-cols-5 gap-0"> {/* vorher: gap-6 px-5 md:px-20  */}
    {categories.map(({ node }) => {
      return (
        <Card key={node.id}>
          <Link to={`/categories/${node.slug}`}>
            <Image
              alt="Category Image"
              className=""
              image={node.image}
            />
           <p className="px-4 py-6 text-4xl cardbg">{node.name}</p>
          </Link>
        </Card>
      )
    })}
  </div>
)

CategoryList.propTypes = {
  categories: PropTypes.array,
}

export default CategoryList

import React from "react"

const FloatingText = ({ children }) => (
  <div className="text-gray-300 px-10 pt-5 md:pt-10 text-4xl font-extralight text-left">
    {children}
  </div>

)

export default FloatingText

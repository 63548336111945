import React from "react"
import { StaticQuery, graphql } from "gatsby"

import Layout from "~/components/layout"
import SEO from "~/components/seo"
import CategoryList from "~/components/category-list"
import PageHeading from "~/components/styled/page-heading"
import FloatingText from "~/components/styled/floatingtext"
import EmailListForm from "~/components/emaillistform"


const IndexPage = ({ data }) => {
  const seo = { title: "0x01 records" }
  const desc = data.strapiGlobal.HomeDescription
  const mail = data.strapiGlobal.MailingDescription
  return (
    <Layout>
      <SEO seo={seo} />
      <PageHeading>0x01 records</PageHeading>
      <div className="grid p-0 md:pr-10 grid-cols-1 md:grid-cols-2">
        <FloatingText>{desc}</FloatingText>
          <div><FloatingText>{mail}</FloatingText><EmailListForm /></div>
      </div>
    </Layout>
  )
}

export const query = graphql`
  query {
    strapiGlobal {
      HomeDescription
      MailingDescription
      }
  }
`

export default IndexPage
